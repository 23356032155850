import { createApp } from 'vue'
import App from './App.vue'
import './assets/tailwind.css';
import router from './router'
import store from './store'
import { i18n, loadLocaleMessages } from './i18n';
import {loadFont} from "@/utils/loadFont";
import {APP_DIRECTION, APP_LOCALE, APP_GTAG_DEBUGGER} from '@/config';
import formatPrice from './directives/formatPrice';
import Toast, { useToast } from "vue-toastification";
import "vue-toastification/dist/index.css";
import { toastOptions } from './config/toastConfig';
import './styles/toast.css';
import VueGtag from 'vue-gtag-next';
import analyticsService from "@/services/analyticsService";
import {formatDate} from "@/directives/formatDate";

const app = createApp(App);

app.config.globalProperties.$locale = APP_LOCALE;
app.config.globalProperties.$direction = APP_DIRECTION;
app.provide('locale', APP_LOCALE);
app.provide('direction', APP_DIRECTION);
app.provide('analytics', analyticsService)

loadFont(APP_LOCALE)

app.use(store);
app.use(i18n);
app.use(Toast, toastOptions);
app.use(router);
app.use(VueGtag, {
    property: {
        id: 'G-EZR86T6SMH'  // Replace with your GA4 Measurement ID
    },
    useDebugger: APP_GTAG_DEBUGGER,
    isEnabled: true,
});

app.config.globalProperties.$toast = useToast();
app.directive('format-price', formatPrice);
app.directive('format-date', formatDate)


loadLocaleMessages(i18n, APP_LOCALE).then(() => {
    app.mount('#app');
});
