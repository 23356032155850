import axiosInstance from './axiosInstance';
import authHeader from './authHeader';
import Product from '@/models/shop/Product';
import UserStory from "@/models/UserStory";

class RecommendationService {
    async fetchRoomProducts() {
        const { data } = await axiosInstance.get('/api/recommendation/room/', { headers: authHeader() });
        return data.map((item: any) => new Product(item));
    }

    async fetchPathProducts(pathKey: string) {
        const { data } = await axiosInstance.get(`/api/recommendation/path/${pathKey}/`, { headers: authHeader() });
        return data.map((item: any) => new Product(item));
    }

    async fetchUserStoryProducts(storyId: number) {
        const { data } = await axiosInstance.get(`/api/recommendation/story/${storyId}/`, { headers: authHeader() });
        return data.map((item: any) => new Product(item));
    }

    async createUserStory(payload: Partial<UserStory>) {
        const { data } = await axiosInstance.post('/api/userstory/stories/', payload, { headers: authHeader() });
        return data;
    }
}

export default new RecommendationService();
