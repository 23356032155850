import { ActionContext, Module } from 'vuex';
import { RootState, RecommendationState } from '@/store/types';
import Product from '@/models/shop/Product';
import { handleError, handleSuccess } from '@/utils/alertHandler';
import productService from '@/services/recommendationService';

const state: RecommendationState = {
    error: null,
    recommendedProducts: [],
};

const getters = {
    allRecommendedProducts: (state: RecommendationState) => state.recommendedProducts,
};

const mutations = {
    SET_RECOMMENDED_PRODUCTS(state: RecommendationState, products: Product[]) {
        state.recommendedProducts = products;
    },
    SET_ERROR(state: RecommendationState, error: string) {
        state.error = error;
    },
};

const actions = {
    async fetchRoomProducts({ commit, dispatch }: ActionContext<RecommendationState, unknown>) {
        await dispatch('loader/setLoading', { isLoading: true, component: 'fetchProducts' }, { root: true });
        try {
            const data = await productService.fetchRoomProducts();
            commit('SET_RECOMMENDED_PRODUCTS', data);
        } catch (error) {
            await handleError(error, true, { action: 'recommendation/fetchRoomProducts' });
            if (error instanceof Error) {
                commit('SET_ERROR', error.message);
            }
        } finally {
            await dispatch('loader/setLoading', { isLoading: false, component: 'fetchProducts' }, { root: true });
        }
    },
    async fetchPathProducts({ commit, dispatch }: ActionContext<RecommendationState, unknown>, pathKey: string) {
        await dispatch('loader/setLoading', { isLoading: true, component: 'fetchProducts' }, { root: true });
        try {
            const data = await productService.fetchPathProducts(pathKey);
            commit('SET_RECOMMENDED_PRODUCTS', data);
        } catch (error) {
            await handleError(error, true, { action: 'recommendation/fetchPathProducts', pathKey });
            if (error instanceof Error) {
                commit('SET_ERROR', error.message);
            }
        } finally {
            await dispatch('loader/setLoading', { isLoading: false, component: 'fetchProducts' }, { root: true });
        }
    },
    async fetchUserStoryProducts({ commit, dispatch }: ActionContext<RecommendationState, unknown>, { userStoryId }: { userStoryId: number }) {
        await dispatch('loader/setLoading', { isLoading: true, component: 'fetchProducts' }, { root: true });
        try {
            const data = await productService.fetchUserStoryProducts(userStoryId);
            commit('SET_RECOMMENDED_PRODUCTS', data);
        } catch (error) {
            await handleError(error, true, { action: 'recommendation/fetchUserStoryProducts', userStoryId });
            if (error instanceof Error) {
                commit('SET_ERROR', error.message);
            }
        } finally {
            await dispatch('loader/setLoading', { isLoading: false, component: 'fetchProducts' }, { root: true });
        }
    },
};

const recommendationModule: Module<RecommendationState, RootState> = {
    namespaced: true,
    state,
    getters,
    mutations,
    actions,
};

export default recommendationModule;
